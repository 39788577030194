import React, { Component, Fragment } from 'react';
import moment from 'moment';
import ModalImage from "react-modal-image";
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { NavLink } from 'react-router-dom';
import {
    Grid, Card, CardHeader, CardContent,
    CardActionArea, Button, TextField, Snackbar
} from "@material-ui/core";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import UpdateIcon from '@material-ui/icons/Update';
import CakeIcon from '@material-ui/icons/Cake';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import Sidebar from '../components/Sidebar_Com';
import Spinner from '../components/Loading_Com';
import StatusModal from '../components/Modal_Com';
import TableV2 from '../components/Table_Com_V2';

// import city from '../assets/data/Cities';
import month from '../assets/data/Months';
import { BarChart, LineChart, PieChart } from '../components/Chart_Com';
import {
    getActiveInactiveMembers, getRegisteredMembersPerYear, getRegisteredMembersPerMonth, chartColor,
} from '../assets/data/ChartsData';
import {
    getDashboard, getMembers, getMerchants,
    getCandidatesChairman, getVoters, getLocations,
    getListOfUpdatedPP, getListAttributeDeliveryPending,
    getExpiredMember,
} from '../logic/APIHandler';
import { isMobile, isTablet } from '../logic/Handler';
import {
    filterDataTwoConditions,
    ExcelFile, ExcelSheet, formatVotersExcel,
    formatMemberBirthdayPDF,
} from '../logic/Handler';
import { styles } from '../styles/CustomTable_Style';
import '../styles/Dashboard_Style.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const idLocale = require('moment/locale/id');
moment.locale('id', idLocale);

class DashboardPage extends Component {

    constructor(props) {
        super(props);

        let addedMonths = [{ name: "All", value: 0 }].concat(month);
        // let addedCities = ["All"].concat(city);
        
        this.state = {
            totalData: 0,
            search: '',
            params: null,

            // Cards state
            totalMember: 0,
            clickMember: true,
            showCharts: true,

            registeredMember: 0,
            clickRegMember: false,
            showMember: false,

            registeredMerchant: 0,
            clickRegMerchant: false,
            showMerchant: false,

            renewalMember: 0,
            listRenewalMember: [],
            clickRenew: false,
            showRenewal: false,

            approvalPP: 0,
            clickApprovalPP: false,
            showApprovalPP: false,
            
            totalAttributeDelivery: 0,
            clickAttributeDelivery: false,
            showAttributeDelivery: false,

            birthdayMember: [],
            clickBday: false,
            showBDFilter: false,

            clickVote: false,
            isVoting: true,
            showVote: false,

            // Spinner state
            isLoading: false, isLoadingChart: false,

            // Table state
            actions: [], columns: [], data: [],

            // Modal state
            id: '', name: '',
            openStatus: false, openRenew: false, openApproveNewMember: false,
            openDecline: false, openDeclineMerchant: false, openJWT: false,
            openApprovePP: false, openDeclinePP: false, openAttributeDelivery: false,

            // Snackbar state
            openSuccessAlert: false, openErrorAlert: false, alertText: "",

            // Filter form state
            allMonths: addedMonths, allCities: [],
            locationNames: [], categories: [],
            currCateg: {category: "All", name: "All"}, currLocName: null,
            currMonth: { name: "All", value: 0 },

            // Charts state
            years: '----', membersPerMonth: [],
            pieChartData: [], barChartData: [], lineChartData: [],

            // Vote state
            hasVotingResult: false, hasVote: 0, hasNotVote: 0, candidatesResult: [],
            pieChartVote: [], votingPeriod: moment().format('YYYY'),
            openClearVoters: false, openClearCandidates: false,
            openReopenVote: false, openStopVoting: false,
            openStartVoting: false, votePeriod: false,

            // Responsive state
            isSidebarOpen: 'slide-out',
        }
        this.excelRef = React.createRef();
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.showDashboardCharts = this.showDashboardCharts.bind(this);
        this.showRegisteredMember = this.showRegisteredMember.bind(this);
        this.showRegisteredMerchant = this.showRegisteredMerchant.bind(this);
        this.showMemberRenewal = this.showMemberRenewal.bind(this);
        this.showApprovalProfilePicture = this.showApprovalProfilePicture.bind(this);
        this.showAttributeDeliveryStatus = this.showAttributeDeliveryStatus.bind(this);
        this.showBirthdayFilter = this.showBirthdayFilter.bind(this);
        this.showVoteResult = this.showVoteResult.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.downloadVoters = this.downloadVoters.bind(this);
    };

    async resetTableParams() {
        localStorage.removeItem('search');
        localStorage.removeItem('sortBy');
        localStorage.removeItem('sort');
        this.setState({ search: '' });
        this.props.history.push({ pathname: '/dashboard/0' });
    };

    // Get dashboard data
    async getDashboard() {
        this.setState({ isLoading: true });
        let dashboard = await getDashboard();

        if ( dashboard[0] && dashboard[0].status === 200 ) {
            const data = dashboard[0].data;
            const votedTemp = data.voting_result ? data.voting_result.candidates.reduce((total, candidate) => total + candidate.total_vote, 0) : 0;

            if (data) {
                this.setState({
                    data: [],
                    // Total in card
                    totalMember: data.total_members,
                    registeredMerchant: data.registered_merchant,
                    registeredMember: data.registered_member,
                    birthdayMember: data.birthday_member,
                    approvalPP: data.total_requested_update_pp,
                    totalAttributeDelivery: data.total_attribute_delivery,
                    renewalMember: data.expired_member || 0,
                    // Voting
                    candidatesResult: data.voting_result ? data.voting_result.candidates : [],
                    hasVotingResult: !!data.voting_result,
                    votingPeriod: data.voting_result ? data.voting_result.periode : moment().format('YYYY'),
                    hasNotVote: (data.total_members - votedTemp),
                    votePeriod: data.voting_period,
                    isLoading: false,
                }, () => {
                    if (localStorage.getItem('last-opened-card') === 'register-member') this.showRegisteredMember();
                    if (localStorage.getItem('last-opened-card') === 'register-merchant') this.showRegisteredMerchant();
                    if (localStorage.getItem('last-opened-card') === 'member-renewal') this.showMemberRenewal();
                    if (localStorage.getItem('last-opened-card') === 'approval-pp') this.showApprovalProfilePicture();
                    if (localStorage.getItem('last-opened-card') === 'attribute-delivery') this.showAttributeDeliveryStatus();

                    if ( localStorage.getItem("admin") === "merchant" ) this.showRegisteredMerchant();
                });
            }
            else {
                this.setState({ isLoading: false })
            }
        }
        else if ( dashboard[1] && dashboard[1].status === 401 ) {
            this.setState({ openJWT: true, isLoading: false });
        }
        else {
            this.setState({ isLoading: false });
        }
    }

    // Get all locations from API
    async getAllLocations() {
        this.setState({ isLoading: true });
        let resp = await getLocations();
        if ( resp[0] && resp[0].status === 200 ) {
            const result = resp[0].data;
            let categs = [{ category: "All", name: "All" }];
            let names = [
                { category: "Region", name: "All" },
                { category: "Chapter", name: "All" },
            ];
            result.forEach(res => {
                if ( categs.filter(temp => temp.category === res.category).length < 1 ) {
                    categs.push(res);
                }
                if ( res.category !== "Pusat" && res.category !== "Luar Wilayah" ) {
                    names.push(res);
                }
            });
            this.setState({
                categories: categs,
                locationNames: names,
                isLoading: false,
            });
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            this.setState({ isLoading: false });
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({ isLoading: false });
        }
    }

    // Get data for charts
    async getChartData() {
        this.setState({ isLoadingChart: true });
        let resPie = await getActiveInactiveMembers();
        let resBar = await getRegisteredMembersPerYear();
        let resLine = await getRegisteredMembersPerMonth();
        
        this.setState({
            years: resBar[0],
            pieChartData: resPie,
            barChartData: resBar[1],
            lineChartData: resLine,
            isLoadingChart: false,
        });
    }

    // Handle member card onClick -> go to member page
    async showDashboardCharts() {
        this.setState({
            showCharts: true, clickMember: true,
            showMember: false, clickRegMember: false,
            showMerchant: false, clickRegMerchant: false,
            showRenewal: false, clickRenew: false,
            showBDFilter: false, clickBday: false,
            showVote: false, clickVote: false,
            showApprovalPP: false, clickApprovalPP: false,
            showAttributeDelivery: false, clickAttributeDelivery: false,
        });
    }

    // Close change status modal
    handleClose() {
        this.setState({
            openStatus: false, openApproveNewMember: false, openRenew: false,
            openDecline: false, openDeclineMerchant: false,
            openApprovePP: false, openDeclinePP: false, openAttributeDelivery: false,
            openClearVoters: false, openReopenVote: false, openClearCandidates: false,
            openErrorAlert: false, openStopVoting: false, openStartVoting: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({ isSidebarOpen: 'slide-in' });
        }
        else {
            this.setState({ isSidebarOpen: 'slide-out' });
        }
    }

    // Handle dropdown onChange event
    handleChapterChange = (_, values) => this.setState({ currCateg: values, currLocName: null }, () => {});
    handleNameChange    = (_, values) => this.setState({ currLocName: values }, () => {});
    handleMonthChange   = (_, values) => this.setState({ currMonth: values }, () => {});

    // Show registered member table
    async showRegisteredMember(params) {
        localStorage.setItem('last-opened-card', 'register-member');
        this.setState({ isLoading: true });
        if (!this.state.showMember || (this.state.showMember && this.state.params.page !== this.props.match.params.page)) {
            const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
            const payload = {
              page: params && params.page ? params.page : page,
              sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'member_card_id',
              sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'desc',
              status: 'registered',
              ...this.state.search && { search: this.state.search },
            };

            this.setState({ params: payload });
            localStorage.setItem('sortBy', payload.sortBy);
            localStorage.setItem('sort', payload.sort);

            let members = await getMembers(payload, false);
            const cols = [
                { 
                    title: "Nama",  field: "name", tooltip: "Nama member",
                },
                { 
                    title: "Nomor Kontak",  field: "phone_number", tooltip: "Nomor kontak member",
                },
                { 
                    title: "Membership",
                    field: "membership",
                    tooltip: "Status membership",
                    render: rowData =>
                        rowData.membership === "silver" ? (
                            <h5 className="membership-bar-silver">Silver</h5>
                        ) : rowData.membership === "platinum" ? (
                            <h5 className="membership-bar-platinum">Platinum</h5>
                        ) : (
                            <h5 className="membership-bar-honorary">Honorary</h5>
                        )
                },
                {
                    title: "Foto KTP",
                    field: "national_identification",
                    tooltip: "Foto KTP member",
                    render: rowData =>
                        rowData.national_identification? (
                            <div className="table-preview-img">
                                <ModalImage
                                    small={rowData.national_identification}
                                    large={rowData.national_identification}
                                    hideDownload={true}
                                />
                            </div>
                        ) : ( <p>Tak ada foto</p> )
                },
                {
                    title: "Foto Diri",
                    field: "photo",
                    tooltip: "Foto diri member",
                    render: rowData =>
                        rowData.photo? (
                            <div className="table-preview-img">
                                <ModalImage
                                    small={rowData.photo}
                                    large={rowData.photo}
                                    hideDownload={true}
                                />
                            </div>
                        ) : ( <p>Tak ada foto</p> )
                },
                {
                    title: "Foto Bukti Bayar",
                    field: "proof_of_payment",
                    tooltip: "Foto bukti bayar",
                    render: rowData =>
                        rowData.proof_of_payment? (
                            <div className="table-preview-img">
                                <ModalImage
                                    small={rowData.proof_of_payment}
                                    large={rowData.proof_of_payment}
                                    hideDownload={true}
                                />
                            </div>
                        ) : ( <p>Tak ada foto</p> )
                },
            ];
            const acts = [
                {
                    icon: () => <FindInPageIcon />,
                    tooltip: "Lihat Detail Member",
                    onClick: (_, rowData) => {
                        this.props.history.push({ pathname: "/member-detail/" + rowData._id });
                    },
                },
                {
                    icon: () => <CheckCircleOutlineIcon />,
                    tooltip: "Approve Member",
                    onClick: (_, rowData) => this.setState({ openApproveNewMember: true, id: rowData._id, name: rowData.name }),
                },
                {
                    icon: () => <HighlightOffIcon />,
                    tooltip: "Decline Member",
                    onClick: (_, rowData) => this.setState({ openDecline: true, id: rowData._id, name: rowData.name }),
                },
            ]
            setTimeout(() => {
                this.setState({
                    columns: cols,
                    actions: acts,
                    data: members[0] && members[0].data ? members[0].data.datas : [],
                    totalData: members[0] && members[0].data ? members[0].data.total : 0,
                    showCharts: false, clickMember: false,
                    showMember: true, clickRegMember: true,
                    showMerchant: false, clickRegMerchant: false,
                    showRenewal: false, clickRenew: false,
                    showBDFilter: false, clickBday: false,
                    showVote: false, clickVote: false,
                    showApprovalPP: false, clickApprovalPP: false,
                    showAttributeDelivery: false, clickAttributeDelivery: false,
                    isLoading: false,
                });
            }, 500);
        }
    }

    // Show registered merchant table
    async showRegisteredMerchant(params) {
        localStorage.setItem('last-opened-card', 'register-merchant');
        this.setState({ isLoading: true });
        if (!this.state.showMerchant || (this.state.showMerchant && this.state.params.page !== this.props.match.params.page)) {
            const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
            const payload = {
                page: params && params.page ? params.page : page,
                sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'created_at',
                sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'asc',
                status: 'registered',
                ...this.state.search && { search: this.state.search },
            };

            this.setState({ params: payload });
            localStorage.setItem('sortBy', payload.sortBy);
            localStorage.setItem('sort', payload.sort);

            let merchants = await getMerchants(payload, false);
            const filteredMerchant = merchants[0] && merchants[0].data ? merchants[0].data.datas : [];
            const cols = [
                { title: "Nama Usaha", field: "name", tooltip: "Nama usaha" },
                { title: "Nama Pemilik", field: "applier_name", tooltip: "Nama pemilik usaha" },
                { title: "Nomor Kontak", field: "phone_number", tooltip: "Nomor kontak merchant" },
                { title: "Email", field: "email", tooltip: "Email merchant" },
            ];
            const acts = [
                {
                    icon: () => <FindInPageIcon />,
                    tooltip: "Lihat Detail Merchant",
                    onClick: (_, rowData) => {
                        this.props.history.push({ pathname: "/merchant-benefit-detail/" + rowData._id });
                    }
                },
                {
                    icon: () => <CheckCircleOutlineIcon />,
                    tooltip: "Approve Merchant",
                    onClick: (_, rowData) => {
                        this.props.history.push({ pathname: "/merchant-benefit-detail/" + rowData._id });
                    }
                },
                {
                    icon: () => <HighlightOffIcon />,
                    tooltip: "Decline Merchant",
                    onClick: (_, rowData) => this.setState({ openDeclineMerchant: true, id: rowData._id, name: rowData.name }),
                },
            ];
            this.setState({
                columns: cols,
                actions: acts,
                data: filteredMerchant,
                totalData: merchants[0] && merchants[0].data ? merchants[0].data.total : 0,
                showCharts: false, clickMember: false,
                showMember: false, clickRegMember: false,
                showMerchant: true, clickRegMerchant: true,
                showRenewal: false, clickRenew: false,
                showBDFilter: false, clickBday: false,
                showVote: false, clickVote: false,
                showApprovalPP: false, clickApprovalPP: false,
                showAttributeDelivery: false, clickAttributeDelivery: false,
                isLoading: false,
            });
        }
    }

    // Show members whose membership are expired
    async showMemberRenewal(params) {
        localStorage.setItem('last-opened-card', 'member-renewal');
        this.setState({ isLoading: true });
        if (!this.state.showRenewal || (this.state.showRenewal && this.state.params.page !== this.props.match.params.page)) {
            const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
            const payload = {
                page: params && params.page ? params.page : page,
                sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'created_at',
                sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'asc',
                ...this.state.search && { search: this.state.search },
            };

            this.setState({ params: payload });
            localStorage.setItem('sortBy', payload.sortBy);
            localStorage.setItem('sort', payload.sort);

            const resp = await getExpiredMember(payload, false);
            const cols = [
                {
                    title: "No Member", field: "member_card_id", tooltip: "ID kartu member",
                },
                { 
                    title: "Nama",  field: "name", tooltip: "Nama member",
                    cellStyle: { width: "250px", maxWidth: "250px" },
                },
                { 
                    title: "Nomor Kontak",  field: "phone_number", tooltip: "Nomor kontak member",
                },
                { 
                    title: "Tanggal Kadaluarsa",  field: "expired_membership", tooltip: "Tanggal kadaluarsa member",
                    render: rowData => <div>{moment(rowData.expired_membership).format('DD MMMM YYYY')}</div>
                },
                { 
                    title: "Membership",
                    field: "membership",
                    tooltip: "Status membership",
                    render: rowData =>
                        rowData.membership === "silver" ? (
                            <h5 className="membership-bar-silver">Silver</h5>
                        ) : rowData.membership === "platinum" ? (
                            <h5 className="membership-bar-platinum">Platinum</h5>
                        ) : (
                            <h5 className="membership-bar-honorary">Honorary</h5>
                        )
                },
                { 
                    title: "Status", 
                    field: "status",
                    tooltip: "Status member",
                    render: () => (<h5 className="status-bar-inactive">Expired</h5>),
                },
            ]
            const acts = [
                {
                    icon: () => <FindInPageIcon />,
                    tooltip: "Lihat Detail Member",
                    onClick: (_, rowData) => {
                        this.props.history.push({ pathname: "/member-detail/" + rowData.member_card_id });
                    }
                },
                {
                    icon: () => <UpdateIcon />,
                    tooltip: "Perbaharui Membership",
                    onClick: (_, rowData) => this.setState({ openRenew: true, id: rowData._id, name: rowData.name }),
                },
                {
                    icon: () => <CheckCircleOutlineIcon />,
                    tooltip: "Ubah Status Member",
                    onClick: (_, rowData) => this.setState({ openStatus: true, id: rowData._id }),
                },
            ]
            this.setState({
                columns: cols,
                actions: acts,
                data: resp[0] && resp[0].data ? resp[0].data.datas : [],
                totalData: resp[0] && resp[0].data ? resp[0].data.total : 0,
                showCharts: false, clickMember: false,
                showMember: false, clickRegMember: false,
                showMerchant: false, clickRegMerchant: false,
                showRenewal: true, clickRenew: true,
                showBDFilter: false, clickBday: false,
                showVote: false, clickVote: false,
                showApprovalPP: false, clickApprovalPP: false,
                showAttributeDelivery: false, clickAttributeDelivery: false,
                isLoading: false,
            });
        }
    }

    // Show list of updated profile picture request
    async showApprovalProfilePicture(params) {
        localStorage.setItem('last-opened-card', 'approval-pp');
        this.setState({ isLoading: true });
        if (!this.state.showApprovalPP || (this.state.showApprovalPP && this.state.params.page !== this.props.match.params.page)) {
            const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
            const payload = {
                page: params && params.page ? params.page : page,
                sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'created_at',
                sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'asc',
                ...this.state.search && { search: this.state.search },
            };

            this.setState({ params: payload });
            localStorage.setItem('sortBy', payload.sortBy);
            localStorage.setItem('sort', payload.sort);

            const resp = await getListOfUpdatedPP(payload, false);
            const data = (resp[0] && resp[0].status === 200) ? resp[0].data.datas.map(val => ({
                ...val,
                member_card_id: val.member_id.member_card_id,
                name: val.member_id.name,
            })) : [];

            const cols = [
                { 
                    title: "No Member",  field: "member_card_id", tooltip: "Nomor member", cellStyle: { width: "20%" },
                },
                { 
                    title: "Nama",  field: "name", tooltip: "Nama member", cellStyle: { width: "40%" },
                },
                {
                    title: "Foto Terbaru",
                    field: "photo_profile",
                    tooltip: "Foto Terbaru Member",
                    render: rowData => (
                        <div className="table-preview-img">
                            <ModalImage
                                small={rowData.photo_profile}
                                large={rowData.photo_profile}
                                hideDownload={true}
                            />
                        </div>
                    ),
                    cellStyle: { width: "20%" },
                },
            ];
            const acts = [
                {
                    icon: () => <CheckCircleOutlineIcon />,
                    tooltip: "Approve Foto Terbaru",
                    onClick: (_, rowData) => this.setState({ openApprovePP: true, id: rowData._id, name: rowData.member_id.name }),
                },
                {
                    icon: () => <HighlightOffIcon />,
                    tooltip: "Decline Foto Terbaru",
                    onClick: (_, rowData) => this.setState({ openDeclinePP: true, id: rowData._id, name: rowData.member_id.name }),
                },
            ];
            this.setState({
                columns: cols,
                actions: acts,
                data,
                totalData: resp[0] && resp[0].data ? resp[0].data.total : 0,
                showCharts: false, clickMember: false,
                showMember: false, clickRegMember: false,
                showMerchant: false, clickRegMerchant: false,
                showRenewal: false, clickRenew: false,
                showBDFilter: false, clickBday: false,
                showVote: false, clickVote: false,
                showApprovalPP: true, clickApprovalPP: true,
                showAttributeDelivery: false, clickAttributeDelivery: false,
                isLoading: false,
            });
        }
    }

    // Show list of IDE attribute delivery status
    async showAttributeDeliveryStatus(params) {
        localStorage.setItem('last-opened-card', 'attribute-delivery');
        this.setState({ isLoading: true });
        if (!this.state.showAttributeDelivery || (this.state.showAttributeDelivery && this.state.params.page !== this.props.match.params.page)) {
            const page = this.props.match.params ? (Number(this.props.match.params.page) + 1) : 1;
            const payload = {
                page: params && params.page ? params.page : page,
                sortBy: params && params.sortBy ? params.sortBy : localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : 'member_card_id',
                sort: params && params.sort ? params.sort : localStorage.getItem('sort') ? localStorage.getItem('sort') :  'desc',
                ...this.state.search && { search: this.state.search },
            };

            this.setState({ params: payload });
            localStorage.setItem('sortBy', payload.sortBy);
            localStorage.setItem('sort', payload.sort);

            const resp = await getListAttributeDeliveryPending(payload, false);
            const data = (resp[0] && resp[0].status === 200) ? resp[0].data.datas.map(x => {
                let eMoney, polo, jaket = false;
                if (x.attribute_delivery && x.attribute_delivery.length > 0) {
                    x.attribute_delivery.forEach(attribute => {
                        if (attribute.key === 'emoney') eMoney = attribute.isChecked;
                        else if (attribute.key === 'polo') polo = attribute.isChecked;
                        else jaket = attribute.isChecked;
                    });
                }
                return { ...x, eMoney, polo, jaket };
            }) : [];

            const cols = [
                {  title: "No Member",  field: "member_card_id", tooltip: "Nomor member" },
                {  title: "Nama",  field: "name", tooltip: "Nama member", cellStyle: { width: '15%' } },
                {  title: "Kontak",  field: "phone_number", tooltip: "Nomor telepon" },
                {
                  title: "Membership",
                  field: "membership",
                  tooltip: "Status membership",
                  render: (rowData) =>
                    rowData.membership === "silver" ? (
                      <h5 className="membership-bar-silver">Silver</h5>
                    ) : rowData.membership === "platinum" ? (
                      <h5 className="membership-bar-platinum">Platinum</h5>
                    ) : (
                      <h5 className="membership-bar-honorary">Honorary</h5>
                    )
                },
                {
                    title: "Kartu eMoney",
                    field: "eMoney",
                    tooltip: "Status pengiriman kartu eMoney",
                    render: (rowData) => rowData.eMoney ? <h5 className="status-bar-active">Terkirim</h5> : <h5 className="status-bar-inactive">Pending</h5>,
                    cellStyle: { width: '25%' },
                },
                {
                    title: "Polo",
                    field: "polo",
                    tooltip: "Status pengiriman polo",
                    render: (rowData) => rowData.polo ? <h5 className="status-bar-active">Terkirim</h5> : <h5 className="status-bar-inactive">Pending</h5>,
                    cellStyle: { width: '25%' },
                },
                {
                    title: "Jaket",
                    field: "jaket",
                    tooltip: "Status pengiriman jaket",
                    render: (rowData) => (
                        <h5 className={rowData.membership === 'silver' ? "status-bar-suspended" : rowData.jaket ? "status-bar-active" : "status-bar-inactive"}>
                            {rowData.membership === 'silver' ? 'Tidak Dapat' : rowData.jaket ? 'Terkirim' : 'Pending'}
                        </h5>
                    ),
                    cellStyle: { width: '25%' },
                },
            ];
            const acts = [
                {
                    icon: () => <CheckCircleOutlineIcon />,
                    tooltip: "Perbarui Status Pengiriman",
                    onClick: (_, rowData) => this.setState({ openAttributeDelivery: true, id: rowData.member_card_id, name: rowData.name, data: rowData }),
                },
            ];
            this.setState({
                columns: cols,
                actions: acts,
                data,
                totalData: resp[0] && resp[0].data ? resp[0].data.total : 0,
                showCharts: false, clickMember: false,
                showMember: false, clickRegMember: false,
                showMerchant: false, clickRegMerchant: false,
                showRenewal: false, clickRenew: false,
                showBDFilter: false, clickBday: false,
                showVote: false, clickVote: false,
                showApprovalPP: false, clickApprovalPP: false,
                showAttributeDelivery: true, clickAttributeDelivery: true,
                isLoading: false,
            });
        }
    };

    // Show current vote result
    async showVoteResult() {
        this.setState({ isLoading: true });
        if ( this.state.candidatesResult.length <= 0 ) {
            let voted = 0, result = [], numberResult = [];
            let candidates = await getCandidatesChairman();

            if (candidates[0] && candidates[0].status === 200) {
                candidates[0].data.forEach(candidate => {
                    numberResult.push(candidate.total_vote);
                    voted += candidate.total_vote;
                });
                candidates[0].data.forEach(candidate => {
                    if ( candidate.total_vote && candidate.total_vote !== 0 ) {
                        candidate.total_vote = candidate.total_vote / (voted || 1) * 100;
                        candidate.total_vote = candidate.total_vote.toFixed(2);
                    }
                    else {
                        candidate.total_vote = 0;
                    }
                    result.push(candidate);
                });
                this.setState({
                    candidatesResult: result,
                    pieChartVote: numberResult,
                });
            }
            else if (candidates[0] && candidates[0].status === 204) {
                this.setState({ isVoting: false });
            }
            else {
                this.setState({ isLoading: false });
            }

            this.setState({
                showCharts: false, clickMember: false,
                showMember: false, clickRegMember: false,
                showMerchant: false, clickRegMerchant: false,
                showRenewal: false, clickRenew: false,
                showBDFilter: false, clickBday: false,
                showVote: true, clickVote: true,
                showApprovalPP: false, clickApprovalPP: false,
                showAttributeDelivery: false, clickAttributeDelivery: false,
                hasVote: voted,
                hasNotVote: this.state.isVoting? (this.state.totalMember - voted) : this.state.hasNotVote,
                isLoading: false,
            })
        }
        else {
            let voted = 0, result = [], numberResult = [];
            this.state.candidatesResult.forEach(res => {
                numberResult.push(res.total_vote);
                voted += res.total_vote;
            });
            this.state.candidatesResult.forEach(res => {
                res.total_vote = res.total_vote / (voted || 1) * 100;
                res.total_vote = res.total_vote.toFixed(2);
                result.push(res);
            });
            this.setState({
                showCharts: false, clickMember: false,
                showMember: false, clickRegMember: false,
                showMerchant: false, clickRegMerchant: false,
                showRenewal: false, clickRenew: false,
                showBDFilter: false, clickBday: false,
                showVote: true, clickVote: true,
                showApprovalPP: false, clickApprovalPP: false,
                hasVote: voted,
                hasNotVote: (this.state.totalMember - voted),
                pieChartVote: numberResult,
                candidatesResult: result,
                isLoading: false,
            });
        }
    };

    // Show birthday filter form
    async showBirthdayFilter() {
        this.setState({ isLoading: true });
        if (!this.state.showBDFilter) {
            this.setState({
                showCharts: false, clickMember: false,
                showMember: false, clickRegMember: false,
                showMerchant: false, clickRegMerchant: false,
                showRenewal: false, clickRenew: false,
                showBDFilter: true, clickBday: true,
                showVote: false, clickVote: false,
                showApprovalPP: false, clickApprovalPP: false,
                showAttributeDelivery: false, clickAttributeDelivery: false,
                isLoading: false,
            });
        }
    };

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ isLoading: true });
        let members = await getMembers();
        let locName = "";
        if ( members[0] && members[0].status === 200 ) {
            let filteredMember = await filterDataTwoConditions(members[0].data, 'status', 'active', 'inactive');
            if ( this.state.currCateg.name !== "All" ) {
                filteredMember = filteredMember.filter(res => res.location_id);
                if ( this.state.currCateg.name === "Pusat" || this.state.currCateg.name === "Luar Wilayah" ) {
                    locName = this.state.currCateg.name;
                    filteredMember = filteredMember.filter(res =>
                        res.location_id.name === this.state.currCateg.name
                    );
                }
                else {
                    if ( this.state.currLocName.name === "All" ) {
                        locName = this.state.currLocName.category;
                        filteredMember = filteredMember.filter(res =>
                            res.location_id.category === this.state.currLocName.category
                        );
                    }
                    else {
                        locName = this.state.currLocName.name;
                        filteredMember = filteredMember.filter(res =>
                            res.location_id.name === this.state.currLocName.name
                        );
                    }
                }
            }
            if ( this.state.currMonth.name !== "All" ) {
                filteredMember = filteredMember.filter(res =>
                    parseInt(res['date_of_birth'].substring(5,7)) === this.state.currMonth.value
                );
            }
            // Sort member by birthday date ascending
            let sortedMember = filteredMember.sort((a,b) => moment(a.date_of_birth).format('DD') - moment(b.date_of_birth).format('DD'));
            sortedMember = filteredMember.sort((a,b) => moment(a.date_of_birth).format('MM') - moment(b.date_of_birth).format('MM'));
            // sortedMember = filteredMember.sort((a,b) => moment(a.date_of_birth).format('YYYY') - moment(b.date_of_birth).format('YYYY'));
            
            formatMemberBirthdayPDF(
                sortedMember,
                locName,
                this.state.currMonth
            );
            this.setState({ isLoading: false });
        }
        else if ( members[1] && members[1].status === 401 ) {
            this.setState({
                isLoading: false,
                openJWT: true,
            });
        }
        else {
            let errorTxt = "Gagal mendapatkan data member.";
            if ( members[1].data && members[1].data[0].msg ) {
                errorTxt = members[1].data[0].msg;
            }
            else {
                errorTxt = members[1].message;
            }
            this.setState({
                errorText: errorTxt,
                openErrorAlert: true,
                isLoading: false,
            });
        }
    }

    // Create formatted excel data & download it
    async downloadVoters() {
        this.setState({ isLoading: true });
        let resp = await getVoters();
        if ( resp[0] && resp[0].status === 200 ) {
            let res = formatVotersExcel(resp[0].data);
            this.setState({ excelData: res, isLoading: false, }, () => {
                if ( this.excelRef.current !== null ) {
                    this.excelRef.current.click();
                }
            });
            this.setState({ isLoading: false });
        }
        else {
            this.setState({
                alertText: "Gagal mendapatkan data voters!",
                openErrorAlert: true,
                isLoading: false,
            });
        }
    };

    componentDidMount() {
        this.getAllLocations();
        this.getDashboard();
        if ( localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "database" ) {
            this.getChartData();
        }
        else if ( localStorage.getItem("admin") === "acara" ) {
            this.setState({
                showCharts: false, showMember: false, showMerchant: false,
                showRenewal: false, showBDFilter: true, showVote: false,
                showApprovalPP: false, showAttributeDelivery: false,
            });
        }
    };

    componentDidUpdate(_, prevState) {
        setTimeout(() => {
            if (this.state.search.length > 0 && (
              this.state.search.substring(0, this.state.search.length - 1) === prevState.search || prevState.search.substring(0, prevState.search.length - 1) === this.state.search
            )) {
                const search = this.state.search.toLowerCase();
                localStorage.setItem('search', search);
                this.props.history.push({ pathname: '/dashboard/0' });
                if (this.state.showMember) this.showRegisteredMember({ search, page: 1 });
                else if (this.state.showMerchant) this.showRegisteredMerchant({ search, page: 1 });
                else if (this.state.showRenewal) this.showMemberRenewal({ search, page: 1 });
                else if (this.state.showApprovalPP) this.showApprovalProfilePicture({ search, page: 1 });
                else if (this.state.showAttributeDelivery) this.showAttributeDeliveryStatus({ search, page: 1 });
            } else if (this.state.search.length === 0 && prevState.search) {
                this.props.history.push({ pathname: '/dashboard/0' });
                if (this.state.showMember) this.showRegisteredMember({ search: '', page: 1 });
                else if (this.state.showMerchant) this.showRegisteredMerchant({ search: '', page: 1 });
                else if (this.state.showRenewal) this.showMemberRenewal({ search: '', page: 1 });
                else if (this.state.showApprovalPP) this.showApprovalProfilePicture({ search: '', page: 1 });
                else if (this.state.showAttributeDelivery) this.showAttributeDeliveryStatus({ search: '', page: 1 });
            }
          }, 700);
    };

    render() {
        return (
            <div className="content">
                { this.state.isLoading || this.state.isLoadingChart ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} />

                <StatusModal
                    open={this.state.openStatus}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="member"
                    id={this.state.id}
                    modalTitle="Ganti Status Member"
                    successText="Berhasil update status member!"
                />
                <StatusModal
                    open={this.state.openApproveNewMember}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="approve-member"
                    id={this.state.id}
                    dataName={this.state.name}
                    dataCategories={this.state.categories.filter(res => res.category !== 'All')}
                    dataLocNames={this.state.locationNames.filter(res => res.name !== 'All')}
                    modalTitle="Approve Calon Member?"
                    successText="Berhasil approve menjadi member!"
                    errorText="Gagal approve menjadi member!"
                />
                <StatusModal
                    open={this.state.openRenew}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="renew-member"
                    id={this.state.id}
                    dataName={this.state.name}
                    modalTitle="Perpanjang Membership"
                    successText="Berhasil memperpanjang membership!"
                    errorText="Gagal memperpanjang membership!"
                />
                <StatusModal
                    open={this.state.openDecline}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="decline-member"
                    id={this.state.id}
                    dataName={this.state.name}
                    modalTitle="Tolak Calon Member?"
                    successText="Berhasil menolak calon member!"
                    errorText="Gagal menolak calon member!"
                />
                <StatusModal
                    open={this.state.openDeclineMerchant}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="decline-merchant"
                    id={this.state.id}
                    dataName={this.state.name}
                    modalTitle="Tolak Calon Merchant?"
                    successText="Berhasil menolak calon merchant!"
                    errorText="Gagal menolak calon merchant!"
                />
                {/* <StatusModal
                    open={this.state.openClearVoters}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    closeModal={() => this.setState({ openClearVoters: false })}
                    modalType="clear-voters"
                    id={this.state.id}
                    color="secondary"
                    modalTitle="Reset Data Voters?"
                    submitText="Reset"
                    successText="Berhasil reset data voters!"
                    errorText="Gagal reset data voters!"
                /> */}
                <StatusModal
                    open={this.state.openStopVoting}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    closeModal={() => this.setState({ openStopVoting: false })}
                    modalType="stop-voting"
                    color="primary"
                    modalTitle="Tutup sesi voting periode ini?"
                    successText="Berhasil menutup voting periode ini!"
                    errorText="Gagal menutup voting periode ini!"
                    votePeriod={this.state.votingPeriod}
                />
                <StatusModal
                    open={this.state.openStartVoting}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    closeModal={() => this.setState({ openStartVoting: false })}
                    modalType="start-voting"
                    color="primary"
                    modalTitle="Buka sesi voting periode ini?"
                    successText="Berhasil membuka voting periode ini!"
                    errorText="Gagal membuka voting periode ini!"
                    votePeriod={this.state.votingPeriod}
                />
                <StatusModal
                    open={this.state.openReopenVote}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    closeModal={() => this.setState({ openReopenVote: false })}
                    modalType="reopen-vote"
                    color="primary"
                    modalTitle="Buka sesi voting periode ini?"
                    successText="Berhasil membuka kembali sesi voting!"
                    errorText="Gagal membuka kembali sesi voting!"
                    votePeriod={this.state.votingPeriod}
                />
                <StatusModal
                    open={this.state.openClearCandidates}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    closeModal={() => this.setState({ openClearCandidates: false })}
                    modalType="clear-candidates"
                    color="primary"
                    modalTitle="Hapus data voting? Rekap data akan otomatis terdownload dan dikirimkan ke dpp@idepreneursclub.org"
                    successText="Berhasil menghapus data voting!"
                    errorText="Gagal menghapus data voting!"
                    votePeriod={this.state.votingPeriod}
                    isTitleCenter
                />
                <StatusModal
                    open={this.state.openApprovePP}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="approve-pp"
                    id={this.state.id}
                    dataName={this.state.name}
                    modalTitle="Approve Profile Picture Terbaru?"
                    successText="Berhasil approve profile picture terbaru!"
                    errorText="Gagal approve profile picture terbaru!"
                />
                <StatusModal
                    open={this.state.openDeclinePP}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="decline-pp"
                    id={this.state.id}
                    dataName={this.state.name}
                    modalTitle="Tolak Profile Picture Terbaru?"
                    successText="Berhasil menolak profile picture terbaru!"
                    errorText="Gagal menolak profile picture terbaru!"
                />
                <StatusModal
                    open={this.state.openAttributeDelivery}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="update-delivery-status"
                    id={this.state.id}
                    dataName={this.state.name}
                    data={this.state.data}
                    modalTitle="Perbarui Status Pengiriman Atribut"
                    successText="Berhasil memperbarui status pengiriman!"
                    errorText="Gagal memperbarui status pengiriman!"
                />
                <StatusModal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Snackbar open={this.state.openSuccessAlert} autoHideDuration={2500} onClose={this.handleClose}>
                    <Alert severity="success">
                        {this.state.alertText}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openErrorAlert} autoHideDuration={2000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {this.state.alertText}
                    </Alert>
                </Snackbar>

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <h2>IDEPRENEURS</h2>
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Grid item xs={12}>
                        <h1 style={{ padding: "0 1.5%", marginTop: "0" }}>DASHBOARD</h1>
                        <Grid container>
                            {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "database"?
                                <Grid item xs={6} lg={4} className="add-margin-bottom">
                                    <Card className={`card-layout ${this.state.clickMember? "":"hover-bright"}`}>
                                        <CardActionArea
                                            onClick={async () => {
                                                await this.resetTableParams();
                                                this.showDashboardCharts();
                                            }}
                                            disabled={this.state.clickMember}
                                        >
                                            <CardHeader title="Total Member" titleTypographyProps={{ variant:'h6' }}
                                                className="card-header" />
                                            <CardContent className="card-content">
                                                <h1>{this.state.totalMember}</h1>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid> : <></>
                            }
                            {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "database"?
                                <Grid item xs={6} lg={4} className="add-margin-bottom">
                                    <Card className={`card-layout ${this.state.clickRegMember? "":"hover-bright"}`}>
                                        <CardActionArea
                                            onClick={async () => {
                                                await this.resetTableParams();
                                                this.showRegisteredMember();
                                            }}
                                            disabled={this.state.clickRegMember}
                                        >
                                            <CardHeader title="Member Terdaftar" titleTypographyProps={{ variant:'h6' }}
                                                className="card-header" />
                                            <CardContent className="card-content">
                                                <h1>{this.state.registeredMember}</h1>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid> : <></>
                            }
                            {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "merchant"?
                                <Grid item xs={6} lg={4} className="add-margin-bottom">
                                    <Card className={`card-layout ${this.state.clickRegMerchant? "":"hover-bright"}`}>
                                        <CardActionArea
                                            onClick={async () => {
                                                await this.resetTableParams();
                                                this.showRegisteredMerchant();
                                            }}
                                            disabled={this.state.clickRegMerchant}
                                        >
                                            <CardHeader title="Merchant Terdaftar" titleTypographyProps={{ variant:'h6' }}
                                                className="card-header" />
                                            <CardContent className="card-content">
                                                <h1>{this.state.registeredMerchant}</h1>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid> : <></>
                            }
                            {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "database"?
                                <Grid item xs={6} lg={4} className="add-margin-bottom">
                                    <Card className={`card-layout ${this.state.clickRenew? "":"hover-bright"}`}>
                                        <CardActionArea
                                            onClick={async () => {
                                                await this.resetTableParams();
                                                this.showMemberRenewal();
                                            }}
                                            disabled={this.state.clickRenew}
                                        >
                                            <CardHeader title="Expired Membership" titleTypographyProps={{ variant:'h6' }}
                                                className="card-header" />
                                            <CardContent className="card-content">
                                                <h1>{this.state.renewalMember}</h1>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid> : <></>
                            }
                            {localStorage.getItem("admin") !== "merchant"?
                                <Grid item xs={6} lg={4} className="add-margin-bottom">
                                    <Card className={`card-layout ${this.state.clickBday? "":"hover-bright"}`}>
                                        <CardActionArea
                                            onClick={async () => {
                                                await this.resetTableParams();
                                                this.showBirthdayFilter();
                                            }}
                                            disabled={this.state.clickBday}
                                        >
                                            <CardHeader title="Download List Ulang Tahun" titleTypographyProps={{ variant:'h6' }}
                                                className="card-header" />
                                            <CardContent className="card-content">
                                                <h1 className='card-icon'>
                                                    <CakeIcon fontSize="large" />
                                                </h1>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid> : <></>
                            }
                            {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "database"?
                                <Grid item xs={6} lg={4} className="add-margin-bottom">
                                    <Card className={`card-layout ${this.state.clickAttributeDelivery? "":"hover-bright"}`}>
                                        <CardActionArea
                                            onClick={async () => {
                                                await this.resetTableParams();
                                                this.showAttributeDeliveryStatus();
                                            }}
                                            disabled={this.state.clickAttributeDelivery}
                                        >
                                            <CardHeader title="Pengiriman Pending" titleTypographyProps={{ variant:'h6' }} className="card-header" />
                                            <CardContent className="card-content">
                                                <h1>{this.state.totalAttributeDelivery}</h1>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid> : <></>
                            }
                            {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "database"?
                                <Grid item xs={6} lg={4} className="add-margin-bottom">
                                    <Card className={`card-layout ${this.state.clickApprovalPP? "":"hover-bright"}`}>
                                        <CardActionArea
                                            onClick={async () => {
                                                await this.resetTableParams();
                                                this.showApprovalProfilePicture();
                                            }}
                                            disabled={this.state.clickApprovalPP}
                                        >
                                            <CardHeader title="Ubah Foto Profil" titleTypographyProps={{ variant:'h6' }} className="card-header" />
                                            <CardContent className="card-content">
                                                <h1>{this.state.approvalPP}</h1>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid> : <></>
                            }
                            {localStorage.getItem("admin") === "master"?
                                <Grid item xs={6} lg={4} className="add-margin-bottom">
                                    <Card className={`card-layout ${this.state.clickVote? "":"hover-bright"}`}>
                                        <CardActionArea
                                            onClick={async () => {
                                                await this.resetTableParams();
                                                this.showVoteResult();
                                            }}
                                            disabled={this.state.clickVote}
                                        >
                                            <CardHeader title="Hasil Vote" titleTypographyProps={{ variant:'h6' }}
                                                className="card-header" />
                                            <CardContent className="card-content">
                                                <h1 className='card-icon'>
                                                    <HowToVoteIcon fontSize="large" />
                                                </h1>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid> : <></>
                            }
                        </Grid>
                    </Grid>

                    <Grid container className={this.state.showMember? '' : 'hide'}>
                        <Grid item xs={12} className="registered-table" style={{ padding: "0 1.4%" }}>
                            <TableV2
                                title="Member Terdaftar"
                                path="/dashboard"
                                columns={this.state.columns}
                                actions={this.state.actions}
                                data={this.state.data}
                                total={this.state.totalData}
                                search={this.state.search}
                                setSearch={val => this.setState({ search: val })}
                                refetchTableData={params => this.showRegisteredMember(params)}
                                params={this.state.params}
                                footerBtn={this.state.data && this.state.data.length > 0 ? (
                                    <NavLink
                                        className="btn-link"
                                        style={{ textDecoration: 'none' }}
                                        to={`/member-detail/${this.state.data[this.state.data.length-1]._id}?status=registered`}
                                    >
                                        <Button variant="contained" className="add-btn-table" style={styles({ isMobile, isTablet }).addButton}>
                                            Review All Registered Member
                                        </Button>
                                    </NavLink>
                                ) : null}
                                hideFooterBtn
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={this.state.showMerchant? '' : 'hide'}>
                        <Grid item xs={12} className="registered-table" style={{ padding: "0 1.4%" }}>
                            <TableV2
                                title="Merchant Terdaftar"
                                path="/dashboard"
                                columns={this.state.columns}
                                actions={this.state.actions}
                                data={this.state.data}
                                total={this.state.totalData}
                                search={this.state.search}
                                setSearch={val => this.setState({ search: val })}
                                refetchTableData={params => this.showRegisteredMerchant(params)}
                                params={this.state.params}
                                hideFooterBtn
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={this.state.showRenewal? '' : 'hide'}>
                        <Grid item xs={12} className="registered-table" style={{ padding: "0 1.4%" }}>
                            <TableV2
                                title="Tabel Member Kadaluarsa"
                                path="/dashboard"
                                columns={this.state.columns}
                                actions={this.state.actions}
                                data={this.state.data}
                                total={this.state.totalData}
                                search={this.state.search}
                                setSearch={val => this.setState({ search: val })}
                                refetchTableData={params => this.showMemberRenewal(params)}
                                params={this.state.params}
                                hideFooterBtn
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={this.state.showApprovalPP? '' : 'hide'}>
                        <Grid item xs={12} className="registered-table" style={{ padding: "0 1.4%" }}>
                            <TableV2
                                title="Tabel Approval Profile Picture"
                                path="/dashboard"
                                columns={this.state.columns}
                                actions={this.state.actions}
                                data={this.state.data}
                                total={this.state.totalData}
                                search={this.state.search}
                                setSearch={val => this.setState({ search: val })}
                                refetchTableData={params => this.showApprovalProfilePicture(params)}
                                params={this.state.params}
                                hideFooterBtn
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={this.state.showAttributeDelivery? '' : 'hide'}>
                        <Grid item xs={12} className="registered-table" style={{ padding: "0 1.4%" }}>
                            <TableV2
                                title="Tabel Status Pengiriman Atribut"
                                path="/dashboard"
                                columns={this.state.columns}
                                actions={this.state.actions}
                                data={this.state.data}
                                total={this.state.totalData}
                                search={this.state.search}
                                setSearch={val => this.setState({ search: val })}
                                refetchTableData={params => this.showAttributeDeliveryStatus(params)}
                                params={this.state.params}
                                hideFooterBtn
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={this.state.showBDFilter? '' : 'hide'}>
                        <Grid item xs={12} className="birthday-filter-layout">
                            <h2 style={{ marginTop: "0", marginBottom: "25px" }}>Filter List Ulang Tahun</h2>
                            <form onSubmit={this.handleSubmit}>

                            {/* Input for birth month */}
                            <Autocomplete
                                disableClearable
                                options={this.state.allMonths}
                                getOptionLabel={(option) => option.name}
                                onChange={this.handleMonthChange}
                                value={this.state.currMonth}
                                className="full-width tf-label add-margin-bottom"
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Bulan Lahir"
                                        variant="outlined"
                                    />}
                            />

                            {/* Input for location categories */}
                            <Autocomplete
                                disableClearable
                                options={this.state.categories}
                                getOptionLabel={(option) => option.category}
                                onChange={this.handleChapterChange}
                                value={this.state.currCateg}
                                className="full-width add-margin-bottom tf-label"
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Kategori Wilayah"
                                        variant="outlined"
                                    />}
                            />
                            {/* Input for location name */}
                            { this.state.currCateg.name !== "All" && this.state.currCateg.name !== "Pusat"
                                && this.state.currCateg.name !== "Luar Wilayah" ?
                                    <Autocomplete
                                        options={this.state.locationNames.filter(res =>
                                            res.category === this.state.currCateg.category
                                        )}
                                        getOptionLabel={(option) => option.name}
                                        onChange={this.handleNameChange}
                                        value={this.state.currLocName}
                                        className="full-width tf-label"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Nama"
                                                placeholder="Pilih Nama Wilayah"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />}
                                    /> : <></>
                            }

                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ display: "block", marginLeft: "auto" }}
                                    className="add-new-btn"
                                >Download</Button>
                            </form>
                        </Grid>
                    </Grid>

                    {localStorage.getItem("admin") === "master" || localStorage.getItem("admin") === "database"?
                    <Grid container className={this.state.showCharts? '' : 'hide'}>
                        <Grid item xs={12} md={6} className="chart-layout add-margin-bottom">
                            <Card style={{ padding: "1rem" }}>
                                <h3 className="chart-title">
                                    {`Total Register per Bulan (Tahun ${this.state.years[this.state.years.length-1]})`}
                                </h3>
                                <LineChart
                                    labels={[
                                        'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun',
                                        'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des',
                                    ]}
                                    datasetsLabel="Total Member yang bergabung"
                                    data={this.state.lineChartData}
                                    borderColor="burlywood"
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} className="chart-layout add-margin-bottom">
                            <Card style={{ padding: "1rem" }}>
                                <h3 className="chart-title">Total Member Active & Inactive</h3>
                                <PieChart
                                    labels={['Active', 'Inactive']}
                                    datasetsLabel="Member Active dan Inactive"
                                    data={[this.state.pieChartData[0], this.state.pieChartData[1]]}
                                    backgroundColor={['#75cc5e', '#de6666']}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} className="chart-layout add-margin-bottom">
                            <Card style={{ padding: "1rem" }}>
                                <h3 className="chart-title">Total Register per Tahun</h3>
                                <BarChart
                                    labels={this.state.years}
                                    datasetsLabel="Total Member yang bergabung"
                                    data={this.state.barChartData}
                                    backgroundColor="burlywood"
                                />
                            </Card>
                        </Grid>
                    </Grid> : <></>
                    }

                    <Grid container className={this.state.showVote ? '' : 'hide'}>
                        <Grid item xs={12} className="vote-result-layout">
                            <Grid container>
                                <Grid item xs={12} className="add-margin-bottom">
                                    <h2>{this.state.hasVotingResult ? "Hasil Akhir Pemilihan" : "Hasil Sementara Pemilihan"}</h2>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className="pie-vote-container">
                                    <h3 className="overall-vote-subtitle" style={{ minHeight: 0 }}>
                                        {this.state.hasVotingResult ? "Perolehan Akhir" : "Perolehan Sementara"}
                                    </h3>
                                    <PieChart
                                        position="right"
                                        labels={this.state.candidatesResult.map(res => {
                                            return (
                                                res.member_id.name
                                            );
                                        })}
                                        datasetsLabel="Perolehan Sementara"
                                        data={this.state.pieChartVote}
                                        backgroundColor={this.state.candidatesResult.map((_,idx) => {
                                            return ( chartColor[idx] );
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3} lg={4} className="vote-right-border">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h3 className="overall-vote-subtitle">
                                                {this.state.hasVotingResult ? "Member Yang Memberi Vote" : "Member Yang Sudah Memberi Vote"}
                                            </h3>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <h1 style={{ fontSize: "3rem", color: "#75cc5e" }}>
                                                {this.state.hasVote}
                                            </h1>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={6} md={3} lg={4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h3 className="overall-vote-subtitle">
                                                {this.state.hasVotingResult ? "Member Yang Tidak Memberi Vote" : "Member Yang Belum Memberi Vote"}
                                            </h3>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <h1 style={{ fontSize: "3rem", color: "#de6666" }}>
                                                {this.state.hasNotVote}
                                            </h1>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* If voting session has ended */}
                            <Grid container style={{ marginTop: "30px" }}>
                                <Grid item xs={12}>
                                    <h2>Hasil Pemilihan Calon Pengurus Periode {this.state.votingPeriod}</h2>
                                </Grid>

                                {/* mapping candidates and their total votes */}
                                {this.state.candidatesResult.map((value, index) => (
                                    this.state.candidatesResult.length % 2 === 0?
                                        <Grid item xs={12} md={6} key={`res-${index}`} className="vote-result-card-2">
                                            <img src={value.photo} className="vote-result-photo" alt="candidate-img" />
                                            <h3>{value.member_id.name}</h3>
                                            <h1 style={{ fontSize: "2rem"}}>{`${value.total_vote}%`}</h1>
                                        </Grid>
                                    : this.state.candidatesResult.length % 3 === 0?
                                        <Grid item xs={12} sm={6} lg={4} key={`res-${index}`} className="vote-result-card-3">
                                            <img src={value.photo} className="vote-result-photo" alt="candidate-img" />
                                            <h3>{value.member_id.name}</h3>
                                            <h1 style={{ fontSize: "2rem"}}>{`${value.total_vote}%`}</h1>
                                        </Grid>
                                    : <Grid item xs={12} sm={6} md={4} key={`res-${index}`} className="vote-result-card-3">
                                            <img src={value.photo} className="vote-result-photo" alt="candidate-img" />
                                            <h3>{value.member_id.name}</h3>
                                            <h1 style={{ fontSize: "2rem"}}>{`${value.total_vote}%`}</h1>
                                      </Grid>
                                ))}
                                {this.state.hasVotingResult? <></> :
                                    <Grid item xs={12}>
                                        <p style={{ padding: "0 1.1%" }}>
                                            * Total Abstain / Belum Memberi Vote: {this.state.hasNotVote} member
                                        </p>
                                    </Grid>
                                }
                                <Grid item xs={12} className="voters-container">
                                    <Button
                                        variant="contained"
                                        className="btn-color"
                                        style={{ marginRight: 10 }}
                                        onClick={this.downloadVoters}
                                    >
                                        <CloudDownloadIcon style={{ marginRight: 10 }} />
                                        Download Voters
                                    </Button>
                                    {/* <Button
                                        variant="outlined"
                                        onClick={() => this.setState({ openClearVoters: true })}
                                    >
                                        <RemoveCircleOutlineIcon style={{ marginRight: 10 }} />
                                        Clear Log Voters
                                    </Button> */}
                                    {this.state.votePeriod ? (
                                        <Button
                                            variant="outlined"
                                            onClick={() => this.setState({ openStopVoting: true })}
                                            style={{ marginRight: 10 }}
                                        >
                                            <RemoveCircleOutlineIcon style={{ marginRight: 10 }} />
                                            Stop Voting
                                        </Button>
                                    ) : null}
                                    {!this.state.votePeriod ? (
                                        <Button
                                            variant="outlined"
                                            onClick={() => this.setState({ openStartVoting: true })}
                                            style={{ marginRight: 10 }}
                                        >
                                            Start Voting
                                        </Button>
                                    ) : null}
                                    {!this.state.votePeriod && this.state.hasVotingResult ? (
                                        <Fragment>
                                            <Button
                                                variant="outlined"
                                                onClick={() => this.setState({ openReopenVote: true })}
                                                style={{ marginRight: 10 }}
                                            >
                                                Reopen Voting
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => this.setState({ openClearCandidates: true })}
                                            >
                                                Clear Data
                                            </Button>
                                        </Fragment>
                                    ) : null}
                                    <div style={{ display: "inline-block", width: "0", pointerEvents: "none" }}>
                                        <ExcelFile
                                            filename="Data Voters"
                                            element={
                                                <Button innerRef={this.excelRef}></Button>
                                            }
                                        >
                                            <ExcelSheet
                                                dataSet={this.state.excelData}
                                                name="Data Voters"
                                            />
                                        </ExcelFile>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </div>
        );
    }
}

export default DashboardPage;