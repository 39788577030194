import Axios from "axios";
import moment from 'moment';
import { setQueryParam } from "./Handler";
// const BASE_URL = "https://api.idepreneursclub.org/api/";
const BASE_URL = "https://dev.idepreneursclub.org/api/";

const baseAPIParameter = {
  baseURL: BASE_URL,
  timeout: 30000,
  timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
};

/**
 * Translate error code to message
 * @param error: error code (ex: 404)
 */
const handleError = (error) => {
  let errorResponse = null;
  if (error.code === "ECONNABORTED") {
    errorResponse = {
      status: 408,
      error: [{ msg: error.message }],
      message: "Request Time Out"
    };
  } else if (error.response) {
    errorResponse = error.response.data;
  } else {
    errorResponse = {
      status: 501,
      error: [{ msg: "Server Implementation Error" }]
    };
  }
  return errorResponse;
};

/**
 * Handle user login
 * @param data: data required to login
 */
export const hitLogin = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    email: data.email,
    password: data.password
  };

  await Axios({
    method: "post",
    url: "admin/login",
    data: object,
    baseURL: BASE_URL,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update current user's password with user's input
 * @param data: previous and new password
 */
export const changePassword = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "put",
    url: "admin/change_password",
    data: {
      old_password: data.old_password,
      new_password: data.new_password
    },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Generate master pin
 */
export const generatePin = async () => {
  let errorResponse = null,
    tokenData = null,
    status = null;
  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "post",
    url: "config/generate/pin",
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get voters data
 */
export const getVoters = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "get",
    url: "config/get/log-voters",
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Reset voters data
 */
export const deleteVoters = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "put",
    url: "config/delete/log-voters",
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Reset isVote's member to false
 * @param id: member's ID
 */
export const resetVoteStatus = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "put",
    url: "members/reset-voter/" + id,
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get dashboard data
 */
export const getDashboard = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "dashboard",
    baseURL: BASE_URL,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all members from API
 */
export const getMembers = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "get",
    url: isGetAllData ? "members" : "members/pagination/".concat(payload.page, "/20", queryParam),
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific member
 * @param id: member's ID
 */
export const getMember = async (id, isUseDefaultId) => {
  var errorResponse = null;
  var tokenData;
  const baseUrl = isUseDefaultId ? "members/" : "members/details?member_id=";

  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "get",
    url: baseUrl + id,
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get latest member with 'registered' status & total registered members
 */
export const getLatestRegisteredMember = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "get",
    url: "members/fetch/registered",
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get members which membership almost expired
 */
export const getExpiredMember = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "get",
    url: isGetAllData ? "members/fetch/expired" : "members/fetch/expired/pagination/".concat(payload.page, "/20", queryParam),
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new member
 * @param data: data required to create new member
 */
export const postMember = async (data, birthPlace, domicile) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("name", data.name);
  form.append("phone_number", data.phone_number);
  form.append("email", data.email);
  form.append("address", data.address);
  form.append("shipping_address", data.shipping_address);
  if (birthPlace === "Lainnya") {
    form.append("place_of_birth", data.place_of_birth);
  } else {
    form.append("place_of_birth", birthPlace);
  }
  const theDate =
    data.date_of_birth.substring(6, 10) +
    "-" +
    data.date_of_birth.substring(3, 5) +
    "-" +
    data.date_of_birth.substring(0, 2);
  form.append("date_of_birth", theDate);
  if (data.gender) form.append("gender", data.gender);
  form.append("national_identification", data.national_identification[0]);
  if (domicile === "Lainnya") {
    form.append("city", data.city);
  } else {
    form.append("city", domicile);
  }
  form.append("job", data.job);
  if (data.join_reason.length <= 0) form.append("join_reason", " ");
  else form.append("join_reason", data.join_reason);
  form.append("photo", data.photo[0]);
  form.append("membership", data.membership.value);
  form.append("reference_source", data.reference_source);
  form.append("reference", data.reference.value);
  form.append("clothes_size", data.clothes_size.value);
  form.append("chapter", data.chapter ? data.chapter.value : "");
  form.append("registration_source", "admin");
  form.append("proof_of_payment", data.proof_of_payment[0]);
  form.append("isAgree", true);

  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "post",
    url: "auth/register",
    data: form,
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update member's data
 * @param idMember: member's ID which want to be updated
 * @param data: updated data
 * @param pin: manager's pin
 */
export const editMember = async (idMember, data, pin) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  let join_reasons = "";

  if (pin) form.append("pin", pin);
  form.append("name", data.name);
  form.append("phone_number", data.phone_number);
  form.append("email", data.email);
  form.append("address", data.address);
  form.append("shipping_address", data.shipping_address);
  form.append("place_of_birth", data.place_of_birth.value);
  const theDate =
    data.date_of_birth.substring(6, 10) +
    "-" +
    data.date_of_birth.substring(3, 5) +
    "-" +
    data.date_of_birth.substring(0, 2);
  form.append("date_of_birth", theDate);
  form.append("gender", data.gender);
  form.append("city", data.city.value);
  form.append("location_id", data.chapter);
  form.append("job", data.job);
  if (data.join_reason_1) join_reasons += "Menambah Networking,";
  if (data.join_reason_2) join_reasons += "Menambah Konsumen Potensial,";
  if (data.join_reason_3) join_reasons += "Mengembangkan Usaha,";
  if (data.join_reason_4) join_reasons += "Menambah Suplier Potensial,";
  if (data.join_reason_5) join_reasons += "Mencari Rekan Usaha,";
  form.append("join_reason", join_reasons);
  form.append("membership", data.membership.value);
  form.append("reference_source", data.reference_source);
  form.append("reference", data.reference.value);
  form.append("clothes_size", data.clothes_size.value);
  form.append("registration_source", "admin");
  //------------------
  if (data.proof_of_payment.length > 0) {
    form.append("proof_of_payment", data.proof_of_payment[0]);
  }
  if (data.photo.length > 0) {
    form.append("photo", data.photo[0]);
  }
  if (data.national_identification.length > 0) {
    form.append("national_identification", data.national_identification[0]);
  }

  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "put",
    url: "members/update/" + idMember,
    data: form,
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update member's status
 * @param idMember: selected member's ID to change their status
 * @param statusMember: new status ('registered', 'inactive', or 'active')
 */
export const updateStatusMember = async (idMember, data) => {
  var errorResponse = null;
  var tokenData;
  var status;

  let object = {
    pin: data.pin ? data.pin : "",
    status: data.status.value
  };

  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "put",
    url: "members/update/status/" + idMember,
    data: object,
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Approve member
 * @param idMember: member's ID which want to be approved
 */
export const approveMember = async (idMember, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    subscription_year: 1,
    location_id: data.chapter
  };

  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "put",
    url: "members/approve/" + idMember,
    data: object,
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Renew membership
 * @param idMember: member's ID which want to be renew
 */
export const renewMember = async (idMember, subscription_year) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "put",
    url: "members/renew-membership/" + idMember,
    data: subscription_year,
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete member or to be member
 * @param idMember: member's ID that want to be deleted
 */
export const deleteMember = async (idMember) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "members/delete/" + idMember,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all executive boards from API
 */
export const getExecutiveBoards = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "boards" : "boards/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific executive board
 * @param id: executive board's ID
 */
export const getExecutiveBoard = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "boards/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new executive board
 * @param data: data required to create new executive board
 */
export const postExecutiveBoard = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  if (data.member_id) {
    if (data.name && data.name !== "") {
      form.append("name", data.name);
    } else {
      form.append("member_id", data.member_id.value);
    }
    form.append("title", data.title.label);
    form.append("position_id", data.title.value);
    form.append("category", data.category);
    if (data.category === "pengurus") {
      form.append("location_id", data.chapter);
    }
    if (data.image_executiveboard)
      form.append("image_executiveboard", data.image_executiveboard[0]);
  } else {
    form.append("name", data.name);
    form.append("title", data.title);
    form.append("category", data.category);
    if (data.chapter) form.append("location_id", data.chapter);
    if (data.image_executiveboard)
      form.append("image_executiveboard", data.image_executiveboard[0]);
  }

  await Axios({
    method: "post",
    url: "boards/",
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update executive board's data
 * @param idExecutive: executive board's ID which want to be updated
 * @param data: updated data
 */
export const editExecutiveBoard = async (idExecutive, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  if (data.member_id) {
    form.append("member_id", data.member_id.value);
    form.append("title", data.title.label);
    form.append("position_id", data.title.value);
    form.append("category", data.category);
    if (data.category === "pengurus") {
      form.append("location_id", data.chapter);
    }
    if (data.image_executiveboard.length > 0)
      form.append("image_executiveboard", data.image_executiveboard[0]);
  }
  else {
    if (data.name) form.append("name", data.name);
    form.append("category", data.category);
    form.append("title", data.title.label);
    form.append("position_id", data.title.value);
    if (data.chapter) form.append("location_id", data.chapter);
    if (data.image_executiveboard && data.image_executiveboard.length > 0)
      form.append("image_executiveboard", data.image_executiveboard[0]);
  }

  await Axios({
    method: "put",
    url: "boards/update/" + idExecutive,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete executive board
 * @param idExecutive: executive board's ID that want to be deleted
 */
export const deleteExecutiveBoard = async (idExecutive) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "boards/delete/" + idExecutive,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all admins
 */
export const getAdmins = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "admin" : "admin/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific admin
 * @param id: admin's ID
 */
export const getAdmin = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "admin/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new admin
 * @param data: data required to create new admin
 */
export const postAdmin = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];
  object = {
    name: data.name,
    email: data.email,
    level: data.level.value
  };
  await Axios({
    method: "post",
    url: "admin/create",
    data: object,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update admin's data
 * @param idAdmin: admin's ID which want to be updated
 * @param data: updated data
 */
export const editAdmin = async (idAdmin, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];
  object = {
    name: data.name,
    email: data.email
  };
  await Axios({
    method: "put",
    url: "admin/update/" + idAdmin,
    data: object,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete event category
 * @param idAdmin: category's ID that want to be deleted
 */
export const deleteAdmin = async (idAdmin) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "admin/delete/" + idAdmin,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all event categories from API
 */
export const getEventCategories = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "event-category" : "event-category/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific event category
 * @param id: event category's ID
 */
export const getEventCategory = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "event-category/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new event category
 * @param data: data required to create new event category
 */
export const postEventCategory = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  form.append("name", data.name);
  form.append("description", data.description);
  form.append("score", parseInt(data.score));
  form.append("image", data.image[0]);
  await Axios({
    method: "post",
    url: "event-category",
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update event category's data
 * @param idCategory: event category's ID which want to be updated
 * @param data: updated data
 */
export const editEventCategory = async (idCategory, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  form.append("name", data.name);
  form.append("description", data.description);
  form.append("score", parseInt(data.score));
  //------------------
  if (data.image.length > 0) {
    form.append("image", data.image[0]);
  }

  await Axios({
    method: "put",
    url: "event-category/update/" + idCategory,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete event category
 * @param idCategory: category's ID that want to be deleted
 */
export const deleteEventCategory = async (idCategory) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "event-category/delete/" + idCategory,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Get all events from API
 */
export const getEvents = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "events" : "events/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific event
 * @param id: event's ID
 */
export const getEvent = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "events/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new event
 * @param data: data required to create new event
 * @param eventCity: event's city value
 */
export const postEvent = async (data, eventCity) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  if (eventCity !== "Lainnya") {
    form.append("city", eventCity);
  } else {
    form.append("city", data.city);
  }
  form.append("location_id", data.chapter);
  form.append("name", data.name);
  form.append("event_categories", data.event_category.id);
  form.append("pic", data.pic ? data.pic.label : "");
  form.append("pic_contact", data.pic_contact);
  const theDate =
    data.date.substring(6, 10) +
    "/" +
    data.date.substring(3, 5) +
    "/" +
    data.date.substring(0, 2);
  const fullDate = theDate + " " + data.time;
  form.append("date", moment(fullDate));
  form.append("description", data.description);
  form.append("address", data.address);
  form.append("address_link", data.address_link);
  form.append("banner", data.banner[0]);
  form.append("thumbnail", data.thumbnail[0]);

  await Axios({
    method: "post",
    url: "events/",
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update event's data
 * @param idEvent: event's ID which want to be updated
 * @param data: updated data
 * @param eventCity: event's city value
 * @param isPastEvent: true if event category="past"
 */
export const editEvent = async (idEvent, data, eventCity, isPastEvent) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData(),
    object = [];
  if (!isPastEvent) {
    if (eventCity !== "Lainnya") {
      form.append("city", eventCity);
    } else {
      form.append("city", data.city);
    }
    form.append("location_id", data.chapter);
    form.append("name", data.name);
    form.append("event_categories", data.event_category.id);
    form.append("pic", data.pic ? data.pic.label : "");
    form.append("pic_contact", data.pic_contact);
    const theDate =
      data.date.substring(6, 10) +
      "/" +
      data.date.substring(3, 5) +
      "/" +
      data.date.substring(0, 2);
    const fullDate = theDate + " " + data.time;
    form.append("date", moment(fullDate));
    form.append("description", data.description);
    form.append("address", data.address);
    form.append("address_link", data.address_link);
    form.append("video_url", data.video_url);

    //------------------
    if (data.banner.length > 0) {
      form.append("banner", data.banner[0]);
    }
    if (data.thumbnail.length > 0) {
      form.append("thumbnail", data.thumbnail[0]);
    }
  } else {
    object = {
      report: data.report
    };
  }

  await Axios({
    method: "put",
    url: "events/update/" + idEvent,
    data: isPastEvent ? object : form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });

  return [tokenData, errorResponse, status];
};

/**
 * Delete event
 * @param idEvent: event's ID that want to be deleted
 */
export const deleteEvent = async (idEvent) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "events/delete/" + idEvent,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Add new image to gallery
 * @param data: list of images
 */
export const postImageToGallery = async (idEvent, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  data.forEach((val) => {
    form.append("gallery", val.gallery);
  });

  await Axios({
    method: "post",
    url: "events/gallery/upload/" + idEvent,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete image from gallery
 * @param idEvent: event's ID
 */
export const deleteImageInGallery = async (idEvent, index) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "events/gallery/delete/" + idEvent,
    data: { idx_gallery: [index] },
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all partners from API
 */
export const getPartners = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "partnerships" : "partnerships/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific partner
 * @param id: partner's ID
 */
export const getPartner = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "partnerships/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new partner
 * @param data: data required to create new partner
 */
export const postPartner = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("name", data.name);
  if (data.logo && data.logo.length > 0) form.append("logo", data.logo[0]);
  if (data.is_show !== undefined) form.append("is_show", data.is_show);
  await Axios({
    method: "post",
    url: "partnerships/",
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update partner's data
 * @param idPartner: partner's ID which want to be updated
 * @param data: updated data
 */
export const editPartner = async (idPartner, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  form.append("name", data.name);
  form.append("is_show", data.is_show);
  //------------------
  if (data.logo.length > 0) {
    form.append("logo", data.logo[0]);
  }

  await Axios({
    method: "put",
    url: "partnerships/update/" + idPartner,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update partner's is_show value
 * @param idPartner: partner's ID which want to be updated
 * @param data: new is_show value
 */
export const editPartnerIsShow = async (idPartner, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  form.append("is_show", data);

  await Axios({
    method: "put",
    url: "partnerships/update/" + idPartner,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete partner
 * @param idPartner: partner's ID that want to be deleted
 */
export const deletePartner = async (idPartner) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "partnerships/delete/" + idPartner,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all sponsors from API
 */
export const getSponsors = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "sponsors" : "sponsors/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific sponsor
 * @param id: sponsor's ID
 */
export const getSponsor = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "sponsors/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new sponsor
 * @param data: data required to create new sponsor
 */
export const postSponsor = async (data, images) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("name", data.name);
  form.append("is_show", data.is_show);
  form.append("level", data.level.value);
  form.append("general", data.general);
  form.append("description", data.description);
  form.append("facebook", data.facebook);
  form.append("instagram", data.instagram);
  form.append("twitter", data.twitter);
  form.append("website", data.website);
  form.append("tiktok", data.tiktok);
  form.append("whatsapp", data.whatsapp);
  if (images.length > 0) {
    images.forEach((res) => {
      form.append("gallery", res.gallery);
    });
  }
  if (data.logo && data.logo.length > 0) form.append("logo", data.logo[0]);

  await Axios({
    method: "post",
    url: "sponsors/",
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update sponsor's data
 * @param idSponsor: sponsor's ID which want to be updated
 * @param data: updated data
 */
export const editSponsor = async (idSponsor, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  const level = data.level.value;
  let form = new FormData();
  form.append("name", data.name);
  form.append("is_show", data.is_show);
  form.append("level", level);
  form.append("general", level === 'Silver' ? data.general : null);
  form.append("description", level !== 'Bronze' ? data.description : '');
  form.append("facebook", level === 'Gold' ? data.facebook : '');
  form.append("instagram", data.instagram);
  form.append("twitter", level === 'Gold' ? data.twitter : '');
  form.append("website", level === 'Gold' ? data.website : '');
  form.append("tiktok", level === 'Gold' ? data.tiktok : '');
  form.append("whatsapp", level === 'Gold' ? data.whatsapp : '');
  if (data.logo.length > 0) {
    form.append("logo", data.logo[0]);
  }

  await Axios({
    method: "put",
    url: "sponsors/update/" + idSponsor,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update sponsor's is_show value
 * @param idSponsor: sponsor's ID which want to be updated
 * @param data: new is_show value
 */
export const editSponsorIsShow = async (idSponsor, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  form.append("is_show", data);

  await Axios({
    method: "put",
    url: "sponsors/update/" + idSponsor,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete sponsor
 * @param idSponsor: sponsor's ID that want to be deleted
 */
export const deleteSponsor = async (idSponsor) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "sponsors/delete/" + idSponsor,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Add new image to sponsor's gallery
 * @param data: list of images
 */
export const postImageSponsor = async (id, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  data.forEach((val) => {
    form.append("gallery", val.gallery);
  });

  await Axios({
    method: "post",
    url: "sponsors/gallery/upload/" + id,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete image from sponsor's gallery
 * @param id: sponsor's ID
 */
export const deleteImageSponsor = async (id, index) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "sponsors/gallery/delete/" + id,
    data: { idx_gallery: [index] },
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all merchants from API
 */
export const getMerchants = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "merchants" : "merchants/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific merchant
 * @param id: merchant's ID
 */
export const getMerchant = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "merchants/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new merchant
 * @param data: data required to create new merchant
 */
export const postMerchant = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    name: data.name,
    applier_name: data.applier_name,
    phone_number: data.phone_number,
    sosmed: data.sosmed,
    address: data.address,
    city: data.city,
    email: data.email,
    pic: data.pic,
    pic_contact: data.pic_contact,
    business_category: data.business_category,
    member_benefit: data.member_benefit,
    terms_and_conditions: data.terms_and_conditions
  };

  await Axios({
    method: "post",
    url: "merchants/",
    data: object,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Approve merchant
 * @param data: data required to approve merchant
 */
export const approveMerchant = async (idMerchant, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  const startDate =
    data.start_date.substring(6, 10) +
    "/" +
    data.start_date.substring(3, 5) +
    "/" +
    data.start_date.substring(0, 2);
  form.append("start_duration", startDate);
  const endDate =
    data.end_date.substring(6, 10) +
    "/" +
    data.end_date.substring(3, 5) +
    "/" +
    data.end_date.substring(0, 2);
  form.append("end_duration", endDate);
  form.append("member_benefit", data.member_benefit);
  form.append("address_link", data.address_link);
  form.append("merchant_benefit", data.merchant_benefit);
  if (data.cooperation_agreement.length > 0) {
    form.append("cooperation_agreement", data.cooperation_agreement[0]);
  }
  if (data.logo.length > 0) {
    form.append("logo", data.logo[0]);
  }

  await Axios({
    method: "post",
    url: "merchants/approve/" + idMerchant,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update merchant's data
 * @param idMerchant: merchant's ID which want to be updated
 * @param data: updated data
 */
export const editMerchant = async (idMerchant, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("name", data.name);
  form.append("applier_name", data.applier_name);
  form.append("phone_number", data.phone_number);
  form.append("address", data.address);
  if ( data.city && data.city.value === "Lainnya" ) {
    form.append("city", data.city_input);
  }
  else {
    form.append("city", data.city ? data.city.value : "");
  }
  form.append("email", data.email);
  form.append("pic", data.pic);
  form.append("pic_contact", data.pic_contact);
  form.append("sosmed", data.sosmed);
  form.append("business_category", data.business_category);
  form.append("terms_and_conditions", data.terms_and_conditions);
  const startDate =
    data.start_date.substring(6, 10) +
    "/" +
    data.start_date.substring(3, 5) +
    "/" +
    data.start_date.substring(0, 2);
  form.append("start_duration", startDate);
  const endDate =
    data.end_date.substring(6, 10) +
    "/" +
    data.end_date.substring(3, 5) +
    "/" +
    data.end_date.substring(0, 2);
  form.append("end_duration", endDate);
  form.append("address_link", data.address_link);
  form.append("member_benefit", data.member_benefit);
  form.append("merchant_benefit", data.merchant_benefit);
  if (data.cooperation_agreement.length > 0) {
    form.append("cooperation_agreement", data.cooperation_agreement[0]);
  }
  if (data.logo.length > 0) {
    form.append("logo", data.logo[0]);
  }
  form.append("status", data.status.value);

  await Axios({
    method: "put",
    url: "merchants/update/" + idMerchant,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete merchant
 * @param idMerchant: merchant's ID that want to be deleted
 */
export const deleteMerchant = async (idMerchant) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "merchants/delete/" + idMerchant,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all news from API
 */
export const getNews = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "banners" : "banners/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific news
 * @param id: news's ID
 */
export const getSpecificNews = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "banners/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new news
 * @param data: data required to create new news
 */
export const postNews = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("name", data.name);
  form.append("category", data.category);
  form.append("title", data.title);
  form.append("sub_title", data.sub_title);
  form.append("description", data.description);
  form.append("position", data.position.value);
  form.append("link", data.link);
  if (data.banner && data.banner.length > 0)
    form.append("banner", data.banner[0]);

  // if ( data.category ) {
  //   form.append("category", data.category.value);
  //   if ( data.category.value === 'event' ) {
  //     form.append("event_id", data.event_id._id);
  //   }
  //   else {
  //     form.append("link", data.link);
  //     if ( data.banner && data.banner.length > 0 ) form.append("banner", data.banner[0]);
  //   }
  // }
  // else {
  //   form.append("category", "news");
  //   form.append("link", data.link);
  //   if ( data.banner && data.banner.length > 0 ) form.append("banner", data.banner[0]);
  // }

  await Axios({
    method: "post",
    url: "banners/",
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update news's data
 * @param idNews: news's ID which want to be updated
 * @param data: updated data
 */
export const editNews = async (idNews, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("title", data.title ? data.title : "");
  form.append("sub_title", data.sub_title ? data.sub_title : "");
  form.append("description", data.description ? data.description : "");
  form.append("position", data.position ? data.position : "");

  form.append("name", data.name);
  form.append("link", data.link);
  if (data.banner.length > 0) {
    form.append("banner", data.banner[0]);
  }

  await Axios({
    method: "put",
    url: "banners/update/" + idNews,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete news
 * @param idNews: news' ID that want to be deleted
 */
export const deleteNews = async (idNews) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "banners/delete/" + idNews,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all candidate from API
 */
export const getCandidatesChairman = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "candidate-chairman" : "candidate-chairman/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific candidate chairman
 * @param id: candidate chairman's ID
 */
export const getCandidateChairman = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "candidate-chairman/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new candidate chairman
 * @param data: data required to create new candidate chairman
 */
export const postCandidateChairman = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("periode", moment().format("YYYY"));
  form.append("member_id", data.member_id.value);
  form.append("member_since", data.member_id.member_card_id.substring(0, 4));
  form.append("visi_misi", data.visi_misi);
  form.append("company_name", data.company_name);
  form.append("business_fields", data.business_fields);
  form.append("last_position", data.last_position);
  form.append("work_program", data.work_program);
  form.append("message", data.message);
  form.append("photo", data.photo[0]);

  await Axios({
    method: "post",
    url: "candidate-chairman/",
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update candidate chairman's data
 * @param idCandidate: candidate chairman's ID which want to be updated
 * @param data: updated data
 */
export const editCandidateChairman = async (idCandidate, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("periode", moment().format("YYYY"));
  form.append("visi_misi", data.visi_misi);
  form.append("company_name", data.company_name);
  form.append("business_fields", data.business_fields);
  form.append("last_position", data.last_position);
  form.append("work_program", data.work_program);
  form.append("message", data.message);
  if (data.photo.length > 0) {
    form.append("photo", data.photo[0]);
  }

  await Axios({
    method: "put",
    url: "candidate-chairman/update/" + idCandidate,
    data: form,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete candidate chairman
 * @param idCandidate: candidate chairman' ID that want to be deleted
 */
export const deleteCandidateChairman = async (idCandidate) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "candidate-chairman/delete/" + idCandidate,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

export const updateVotingStatus = async (type, votePeriod) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "put",
    url: "candidate-chairman/" + type + "/" + votePeriod,
    ...baseAPIParameter,
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all chapter from API
 */
export const getChapters = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "chapter",
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new chapter
 * @param data: data required to create new chapter
 */
export const postChapter = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];
  object = {
    name: data.name
  };
  await Axios({
    method: "post",
    url: "chapter/",
    data: object,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update chapter's data
 * @param idChapter: chapter's ID which want to be updated
 * @param data: updated data
 */
export const editChapter = async (idChapter, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];
  object = {
    name: data.name
  };
  await Axios({
    method: "put",
    url: "chapter/update/" + idChapter,
    data: object,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete chapter
 * @param idChapter: chapter's ID that want to be deleted
 */
export const deleteChapter = async (idChapter) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "chapter/delete/" + idChapter,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

export const getJabatan = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "position" : "position/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

export const getDetailJabatan = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "position/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

export const postJabatan = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  const object = {
    name: data.name,
    score: parseInt(data.score),
  };
  await Axios({
    method: "post",
    url: "position/",
    data: object,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

export const editJabatan = async (id, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = {
    name: data.name,
    score: parseInt(data.score),
  };
  await Axios({
    method: "put",
    url: "position/update/" + id,
    data: object,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

export const deleteJabatan = async (id) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "position/delete/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get all location categories
 */
export const getLocationCategories = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "location-category",
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get all locations
 */
export const getLocations = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    method: "get",
    url: isGetAllData ? "location" : "location/pagination/".concat(payload.page, "/20", queryParam),
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific location
 * @param id: location's ID
 */
export const getLocation = async (id) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "location/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new location
 * @param data: data required to create new location
 */
export const postLocation = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];
  object = {
    category: data.category.value,
    name: data.name
  };
  await Axios({
    method: "post",
    url: "location/",
    data: object,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update location's data
 * @param id: location's ID which want to be updated
 * @param data: updated data
 */
export const editLocation = async (id, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];
  object = {
    category: data.category.value,
    name: data.name
  };
  await Axios({
    method: "put",
    url: "location/update/" + id,
    data: object,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete location
 * @param id: location's ID that want to be deleted
 */
export const deleteLocation = async (id) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "delete",
    url: "location/delete/" + id,
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of requested updated profile picture
 */
export const getListOfUpdatedPP = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "get",
    url: isGetAllData ? "members/request-change-pp/requested" : "members/request-change-pp/requested/pagination/".concat(payload.page, "/20", queryParam),
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Approve / decline member's new profile picture
 * @param id: member id
 */
export const handleUpdatedPP = async (id, isApproved) => {
  var errorResponse = null;
  var tokenData;
  var status;
  const ppStatus = isApproved ? 'approved' : 'declined';

  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "put",
    url: "members/request-change-pp/".concat(id, "/", ppStatus),
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of pending attribute deliveries
 */
export const getListAttributeDeliveryPending = async (payload, isGetAllData = true) => {
  var errorResponse = null;
  var tokenData;
  const queryParam = isGetAllData ? '' : setQueryParam(payload);
  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "get",
    url: "members/attribute-delivery/pending/pagination/".concat(payload.page, "/20", queryParam),
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Update member's attribute delivery status
 * @param id: member card id
 * @param data: list of delivered attributes
 */
export const updateAttributeDelivery = async (id, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  const object = {
    attribute_delivery: [
      { key: "emoney", value: "Kartu eMoney", isChecked: !!data.eMoney },
      { key: "polo", value: "Polo", isChecked: !!data.polo },
    ],
  };
  if (data.membership.value !== "silver") object.attribute_delivery.push({ key: "jaket", value: "Jaket", isChecked: !!data.jaket });

  await Axios({
    ...baseAPIParameter,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    method: "put",
    url: "members/attribute-delivery/update/".concat(id),
    data: object,
  })
    .then(function (response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};
